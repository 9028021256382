import { SendOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Input, message, Upload } from "antd";
import { useEffect, useRef, useState } from "react";
import { Editor } from '@tinymce/tinymce-react';
import * as XLSX from 'xlsx';
import { addListEmailReceiver, doSendEmail, getConfigLimitByUser, saveLogActionSend } from "../../services/apis";
import { consoleLogUtil } from "../../utils/consoleLogUtil";
import { useSelector } from "react-redux";
import LoadingBase from "../../components/LoadingBase";


const SendEmailPage = () => {
    const editorRef = useRef(null);
    const [loadingImport, setLoadingImport] = useState(false);
    const [dataEmailReceiver, setDataEmailReceiver] = useState([]);
    const [contentEmail, setContentEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [totalRemaining, setTotalRemaining] = useState(0);
    const userReducer = useSelector(state => state?.userReducer);
    const [subject, setSubject] = useState();
    const [sender, setSender] = useState('');

    const props = {
        name: 'file',
        accept: '.xlsx,.xls',
        beforeUpload: (file) => {
            console.log('beforeUpload', file)
            setLoadingImport(true);
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                if (dataParse && dataParse.length > 0) {
                    if (dataParse.length < 2501) {
                        dataParse.shift();
                        setDataEmailReceiver([...dataParse.filter(item => item.length > 0).map(subArray => {
                            return {
                              email: subArray[0],
                              name: subArray[1]
                            };
                          })]);
                    } else {
                        message.warning("Số lượng email người nhận vượt quá 2500 email. Vui lòng xoá bớt")
                    }
                }
                setLoadingImport(false);
            };
            reader.readAsBinaryString(file);
            return false;
        },
    };

    const saveLogSend = async () => {
        const params = {
            count_receiver: dataEmailReceiver.length,
            user_id: userReducer?.data?.user_info?.id
        };
        await saveLogActionSend(params);
    }
    const handleChange = (txt) => {
        if (editorRef.current) {
            console.log(txt);
            setContentEmail(txt);
        }
    }

    const updateListEmailReceiver = async () => {
        const params = {
            listEmail: dataEmailReceiver.map(item => (
                {
                    email: item
                }
            ))
        };
        await addListEmailReceiver(params);
    }
    const handleChangeSenderName = (e) => {
        setSender(e.target.value);
    }
    const handleChangeSubject = (e) => {
        setSubject(e.target.value);
    }
    const sendEmailApi = async () => {
        if (totalRemaining <= 0) {
            message.warning("Bạn không thể gửi email do đã hết lượt gửi trong ngày");
            return;
        }

        try {
            setLoading(true);
            const params = {
                lstEmail: dataEmailReceiver,
                body: contentEmail,
                subject: subject,
                senderName: sender,
                user_id: userReducer?.data?.user_info?.id
            }
            const res = await doSendEmail(params);
            setLoading(false);
            consoleLogUtil("doSendEmail", res)
            if (res) {
                if (res.status === 1) {
                    message.success("Dữ liệu gửi thành công. Email đang được máy chủ xử lý");
                    setSubject("");
                    setContentEmail(""); 
                    setDataEmailReceiver([]);
                    Promise.all([getDataRemainingEmails(), updateListEmailReceiver(),saveLogSend()]);
                } else if (res.status === 6) {
                    message.warning("Bạn chưa được cài được giới hạn số lượng gửi email");
                } else {
                    message.warning(res?.msg ?? "Có lỗi xảy ra");
                }
                
            } else {
                message.warning(res?.msg ?? "Có lỗi xảy ra vui lòng thử lại");
            }
        } catch (error) {
            setLoading(false);
            consoleLogUtil("error", error);
            message.error("Có lỗi xảy ra vui lòng thử lại");
        }

    }
    const getDataRemainingEmails = async () => {
        try {
            const params = {
                user_id: userReducer?.data?.user_info?.id
            }
            setLoading(true);
            const res = await getConfigLimitByUser(params);
            setLoading(false);
            if (res && res?.status === 1) {
                setTotalRemaining(res?.data?.limitSend);
            } else {
                setTotalRemaining(0);
            }
        } catch (error) {
            setLoading(false);
            setTotalRemaining(0);
            message.warning("Có lỗi xảy ra vui lòng tải lại");
        }

    }
    const handleSendEmail = () => {
        sendEmailApi();
    }

    const statusButtonSend = () => {
        return dataEmailReceiver?.length > 0 && contentEmail?.length > 0 && subject?.length > 0 && sender?.length > 0;
    }
    useEffect(() => {
        getDataRemainingEmails();
    },[])
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>
                <Upload {...props}>
                    <Button icon={<UploadOutlined />} disabled={loadingImport}>Upload danh sách nhận email</Button>
                    <span style={{paddingLeft: 8}}>Số email: <strong>{dataEmailReceiver.length}</strong></span>
                </Upload>
                <span>Số email còn lại: <strong>{totalRemaining}</strong></span>
                <Button icon={<SendOutlined />} disabled={statusButtonSend() === false} type="primary" onClick={handleSendEmail}>Gửi email</Button>
            </div>
            <Input placeholder="Nhập tên người gửi" style={{ marginBottom: 16, width: 500 }} value={sender} onChange={handleChangeSenderName}/>
            <Input placeholder="Tiêu đề email" style={{ marginBottom: 16 }} value={subject} onChange={handleChangeSubject} />

            <Editor
                apiKey='2lj7b3yzta2d74vqnhrl19g6ucththp7cftbcb8e1x13g17j'
                onInit={(_evt, editor) => editorRef.current = editor}
                onEditorChange={handleChange}
                value={contentEmail}
                initialValue=""
                init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            <LoadingBase loading={loading} />
        </div>
    );
}

export default SendEmailPage;
